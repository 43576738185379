var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "my-modal",
      attrs: {
        "class-name": "dark",
        title: "设置",
        width: "30%",
        height: "80%",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        position: { top: "10%", left: "40%" },
      },
      on: {
        close: function ($event) {
          _vm.open = false
        },
      },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "a-tabs",
        {
          staticStyle: { padding: "0 10px", height: "auto" },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "人员设置" } },
            [
              _c("object-tree", {
                ref: "vehicleTree",
                staticStyle: { height: "35vh" },
                attrs: {
                  "tree-data": _vm.treeCleanerData,
                  "place-holder": "输入工牌编号或姓名进行搜索",
                  "object-title": "车辆",
                  "cache-key": "cleanerTreeDashboard",
                  "object-icon": "carIcon",
                },
                on: { check: _vm.handleCheckCleaner },
              }),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.cleanerSwitch.employeeRealLocation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cleanerSwitch,
                            "employeeRealLocation",
                            $$v
                          )
                        },
                        expression: "cleanerSwitch.employeeRealLocation",
                      },
                    }),
                    _c("span", [_vm._v("展示人员实时位置")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.cleanerSwitch.employeeSelectedNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cleanerSwitch,
                            "employeeSelectedNumber",
                            $$v
                          )
                        },
                        expression: "cleanerSwitch.employeeSelectedNumber",
                      },
                    }),
                    _c("span", [_vm._v("展示选中的人数")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.cleanerSwitch.employeeOnline,
                        callback: function ($$v) {
                          _vm.$set(_vm.cleanerSwitch, "employeeOnline", $$v)
                        },
                        expression: "cleanerSwitch.employeeOnline",
                      },
                    }),
                    _c("span", [_vm._v("展示在线的人数")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "车辆设置" } },
            [
              _c("object-tree", {
                ref: "vehicleTree",
                staticStyle: { height: "35vh" },
                attrs: {
                  "tree-data": _vm.treeVehicleData,
                  "place-holder": "输入车辆编号进行搜索",
                  "object-title": "车辆",
                  "cache-key": "traditionalVehicleTreeDashboard",
                  "object-icon": "carIcon",
                },
                on: { check: _vm.handleCheckTraditionalVehicle },
              }),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.traVehicleSwitch.vehicleRealLocation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.traVehicleSwitch,
                            "vehicleRealLocation",
                            $$v
                          )
                        },
                        expression: "traVehicleSwitch.vehicleRealLocation",
                      },
                    }),
                    _c("span", [_vm._v("展示车辆实时位置")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.traVehicleSwitch.vehicleSelectedNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.traVehicleSwitch,
                            "vehicleSelectedNumber",
                            $$v
                          )
                        },
                        expression: "traVehicleSwitch.vehicleSelectedNumber",
                      },
                    }),
                    _c("span", [_vm._v("展示选中的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.traVehicleSwitch.vehicleOnline,
                        callback: function ($$v) {
                          _vm.$set(_vm.traVehicleSwitch, "vehicleOnline", $$v)
                        },
                        expression: "traVehicleSwitch.vehicleOnline",
                      },
                    }),
                    _c("span", [_vm._v("展示在线的车辆数量")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.traVehicleSwitch.todayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.traVehicleSwitch,
                            "todayForWorkOdom",
                            $$v
                          )
                        },
                        expression: "traVehicleSwitch.todayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示今日出勤里程")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-switch" },
                  [
                    _c("a-switch", {
                      attrs: {
                        "checked-children": "开",
                        "un-checked-children": "关",
                      },
                      model: {
                        value: _vm.traVehicleSwitch.yesterdayForWorkOdom,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.traVehicleSwitch,
                            "yesterdayForWorkOdom",
                            $$v
                          )
                        },
                        expression: "traVehicleSwitch.yesterdayForWorkOdom",
                      },
                    }),
                    _c("span", [_vm._v("展示昨日出勤里程")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bottom-control",
          staticStyle: { "margin-top": "30px", height: "auto" },
        },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(" 取消 "),
              ]),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }