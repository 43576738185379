<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <vxe-modal
    class-name="dark"
    v-model="open"
    title="设置"
    width="30%"
    height="80%"
    class="my-modal"
    @close="open = false"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :position="{ top: '10%', left: '40%' }"
  >
    <a-tabs v-model="activeKey" style="padding: 0 10px; height: auto">
      <a-tab-pane key="1" tab="人员设置">
        <object-tree
          style="height: 35vh"
          ref="vehicleTree"
          :tree-data="treeCleanerData"
          place-holder="输入工牌编号或姓名进行搜索"
          object-title="车辆"
          cache-key="cleanerTreeDashboard"
          object-icon="carIcon"
          @check="handleCheckCleaner"
        />
        <div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="cleanerSwitch.employeeRealLocation" />
            <span>展示人员实时位置</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="cleanerSwitch.employeeSelectedNumber" />
            <span>展示选中的人数</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="cleanerSwitch.employeeOnline" />
            <span>展示在线的人数</span>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="车辆设置">
        <object-tree
          style="height: 35vh"
          ref="vehicleTree"
          :tree-data="treeVehicleData"
          place-holder="输入车辆编号进行搜索"
          object-title="车辆"
          cache-key="traditionalVehicleTreeDashboard"
          object-icon="carIcon"
          @check="handleCheckTraditionalVehicle"
        />

        <div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="traVehicleSwitch.vehicleRealLocation" />
            <span>展示车辆实时位置</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="traVehicleSwitch.vehicleSelectedNumber" />
            <span>展示选中的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="traVehicleSwitch.vehicleOnline" />
            <span>展示在线的车辆数量</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="traVehicleSwitch.todayForWorkOdom" />
            <span>展示今日出勤里程</span>
          </div>
          <div class="my-switch">
            <a-switch checked-children="开" un-checked-children="关" v-model="traVehicleSwitch.yesterdayForWorkOdom" />
            <span>展示昨日出勤里程</span>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="bottom-control" style="margin-top: 30px; height: auto">
      <a-space>
        <a-button @click="handleCancel"> 取消 </a-button>
        <a-button type="primary" @click="handleSave"> 保存 </a-button>
      </a-space>
    </div>
  </vxe-modal>
</template>

<script>
import { Tabs } from 'ant-design-vue'
import ObjectTree from '@/views/monitor/monitorComponents/objectTree'
import { deviceTree } from '@/api/iot/device'
export default {
  components: { ATabs: Tabs, ATabPane: Tabs.TabPane, ObjectTree },
  data() {
    return {
      open: false,
      activeKey: '1',
      treeVehicleData: [],
      treeCleanerData: [],
      cleanerSwitch: {
        employeeRealLocation: true,
        employeeSelectedNumber: true,
        employeeOnline: true
      },
      traVehicleSwitch: {
        vehicleRealLocation: true,
        vehicleSelectedNumber: true,
        vehicleOnline: true,
        todayForWorkOdom: true,
        yesterdayForWorkOdom: true
      }
    }
  },
  watch: {},
  mounted() {
    this.loadLocalConfig()
  },
  methods: {
    handleSetup() {
      this.initTraditionalVehicleTree(2)
      this.initTraditionalVehicleTree(1)
      this.open = true
    },
    initTraditionalVehicleTree(deviceType) {
      const formData = new FormData()
      formData.append('deviceType', deviceType)
      deviceTree(formData).then((response) => {
        console.log('initTraditionalVehicleTree')
        if (deviceType === 1) {
          this.treeVehicleData = response.data
        } else if (deviceType === 2) {
          this.treeCleanerData = response.data
        }
      })
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    handleCheckTraditionalVehicle(checkedList, selectedId, isSameList) {
      console.log('handleCheckTraditionalVehicle', checkedList)
      this.currentTraditionalVehicleCheckedList = checkedList
    },

    handleCheckCleaner(checkedList, selectedId, isSameList) {
      console.log('handleCheckCleaner', checkedList)
      this.currentCleanerCheckedList = checkedList
    },
    loadLocalConfig() {
      const cleanerDashboardConfig = localStorage.getItem('cleanerDashboardConfig')
      if (cleanerDashboardConfig) {
        const tmpConfig = JSON.parse(cleanerDashboardConfig)
        this.cleanerSwitch = { ...tmpConfig.cleanerSwitch }
        this.traVehicleSwitch = { ...tmpConfig.traVehicleSwitch }
      }
    },
    handleSave() {
      // 配置保存在本地
      const cleanerDashboardConfig = {
        cleanerSwitch: this.cleanerSwitch,
        traVehicleSwitch: this.traVehicleSwitch
      }
      localStorage.setItem('cleanerDashboardConfig', JSON.stringify(cleanerDashboardConfig))
      this.$emit('callback', cleanerDashboardConfig)
      this.open = false
    },
    handleCancel() {
      this.open = false
    }
  }
}
</script>
<style lang="less" scoped>
@bgColor: rgba(13, 21, 21, 0.7);
@primaryColor: #00fff1;
@borderColor: #11635f;
@btnColor: #11635f;
@inputColor: #11635f;
@treeSwitchColor: #11635f;
@labelColor: #fff;
@secondBtnColor: #11635f;
@primaryBtnColor: #11635f;
.my-switch {
  padding: 5px 0;
  color: @labelColor;
  > :last-child {
    margin-left: 10px;
  }
}
.bottom-control {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}

/deep/ .vxe-modal--box {
  background-color: @bgColor;
  -webkit-backdrop-filter: saturate(180%) blur(15px);
  -moz-backdrop-filter: saturate(180%) blur(15px);
  backdrop-filter: saturate(180%) blur(15px);
}

/deep/ .vxe-modal--header.is--ellipsis {
  background-color: @bgColor;
}

/deep/ .ant-tabs-nav {
  color: @primaryColor;
}
/deep/ .tree-seach-title {
  color: @labelColor;
}
/deep/ .tree-label {
  color: @labelColor;
}
/deep/ .bottom-control {
  background-color: @bgColor;
  border-top: 1px solid @borderColor;
  .ant-btn {
    border-color: rgba(255, 255, 255, 0.25);
  }
  .ant-btn-primary {
    background-color: @primaryBtnColor;
  }
  .ant-btn-dashed {
    // background-color: @secondBtnColor;
  }
}

/deep/ .vxe-modal--header {
  border-bottom: 1px solid @borderColor;
}

/deep/ .ant-btn.ant-btn-primary {
  background-color: @btnColor;
}
/deep/ .ant-btn {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: @btnColor;
}

/deep/ .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: @btnColor;
  border-color: @btnColor;
}

/deep/ .vxe-modal--title {
  color: @primaryColor;
}
/deep/ .ant-input {
  background-color: @inputColor;
  color: #fff;
  border: 1px solid @borderColor;
}
/deep/ .ant-tree-switcher-icon {
  color: @treeSwitchColor;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  color: @borderColor;
}
/deep/ .ant-tabs-nav .ant-tabs-tab-active {
  color: @primaryColor;
}
/deep/ .ant-tabs-bar {
  border-bottom: 1px solid @borderColor;
}
/deep/ .ant-tabs-ink-bar {
  background-color: @primaryColor;
}
/deep/ .ant-switch {
  background-color: rgba(255, 255, 255, 0.25);
}

/deep/ .ant-switch.ant-switch-checked {
  background-color: @borderColor;
}
</style>
